import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import styled from "styled-components";

import Seo from "../components/seo";
import { Container, Wrapper, Row, Box } from "../components/util";
import { CallToAction, Breadcrumb } from "../components/site";
import Layout from "../components/layout";

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        photo1 {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
          }
        }
        photo2 {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
          }
        }
      }
    }
  }
`;

const Job = (props) => {
  return (
    <Layout>
      <Seo
        title={props.data.markdownRemark.frontmatter.title + ` | Project`}
        description=""
        pathname={props.location.pathname}
      />
      <Breadcrumb
        parentPage="Our Projects"
        parentPageLink="/examples"
        currentPage={props.data.markdownRemark.frontmatter.title}
      />
      <Container>
        <Wrapper maxWidth={850} gap={60}>
          <h1 className="-textCenter">
            {props.data.markdownRemark.frontmatter.title}
          </h1>
          {props.data.markdownRemark.frontmatter.photo1 && (
            <GatsbyImage
              alt={props.data.markdownRemark.frontmatter.title}
              image={
                props.data.markdownRemark.frontmatter.photo1.childImageSharp
                  .gatsbyImageData
              }
            />
          )}
          <ul>
            {props.data.markdownRemark.frontmatter.description.map(
              (item, i) => (
                <li css={{ marginBottom: "25px" }} key={i}>
                  <h4 className="-fontNormal">{item}</h4>
                </li>
              )
            )}
          </ul>
          {props.data.markdownRemark.frontmatter.photo2 && (
            <GatsbyImage
              alt={props.data.markdownRemark.frontmatter.title}
              image={
                props.data.markdownRemark.frontmatter.photo2.childImageSharp
                  .gatsbyImageData
              }
            />
          )}
        </Wrapper>
      </Container>
    </Layout>
  );
};

export default Job;
